import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AddToCart } from 'lib/cart';

import { isCustomizableMeal } from 'lib/product';
import * as typedef from 'lib/product/typedef';
import * as utils from 'lib/product/utils';
import ProductCoverImage from 'lib/product/ProductCoverImage';

import ProductLink from '../ProductLink';
import QuickAddToCartButton from './QuickAddToCartButton';
import styles from './ProductCard.module.css';
import SelectOptionsButton from './SelectOptionsButton';
import { getProductPath } from 'lib/router';
import { useRouter } from 'next/router';

const ProductCard = ({
  product,
  className,
  // showQuickView,
  // isWishlist,
  // onToggleWishlist,
}) => {
  const { push } = useRouter();

  const displayingProductVariant = utils.getDisplayingProductVariant(
    product,
  );

  const { stock, price } = displayingProductVariant;

  const isOutOfStock = stock === 0;

  // const quickViewHandler = () => {
  //   showQuickView(product.id);
  // };

  // const wishlistHandler = () => {
  //   if (isWishlist) {
  //     window.location = process.env.PUBLIC_URL + '/shop/wishlist';
  //   } else {
  //     onToggleWishlist(product, isWishlist);
  //   }
  // };

  return product ? (
    <div className={classNames('product', className)}>
      <figure className="product-media">
        {product.new ? (
          <span className="product-label label-new">New</span>
        ) : (
          ''
        )}
        {product.top ? (
          <span className="product-label label-top">Top</span>
        ) : (
          ''
        )}
        {product.discount ? (
          <span className="product-label label-sale">
            {product.discount}% off
          </span>
        ) : (
          ''
        )}
        {isOutOfStock ? (
          <span className="product-label label-out bg-primary">
            Oh no! We sold out!
          </span>
        ) : (
          ''
        )}

        {product.assets?.length > 0 && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <ProductLink product={product}>
              <ProductCoverImage
                alt="product"
                productAssets={product.assets}
                layout="responsive"
                size={375}
              />
            </ProductLink>
          </div>
        )}

        <AddToCart
          product={product}
          productVariant={displayingProductVariant}
          quantity={1}
        >
          {({
            loading,
            isOutOfStock,
            canAddToCart,
            addToCart,
            isAddingToCart,
          }) => {
            if (isOutOfStock) return null;

            const disabled = !canAddToCart || isAddingToCart;

            return (
              <div className="product-action action-icon-top">
                {isCustomizableMeal(product) ? (
                  <SelectOptionsButton
                    onClick={() => push(getProductPath(product.slug))}
                  />
                ) : (
                  <QuickAddToCartButton
                    isCartLoading={loading}
                    isAddingToCart={isAddingToCart}
                    disabled={disabled}
                    onClick={addToCart}
                  />
                )}
              </div>
            );
          }}
        </AddToCart>

        {/* <button
            className="btn-product btn-quickview"
            title="Quick view"
            onClick={quickViewHandler}
          >
            <span>quick view</span>
          </button> */}
      </figure>

      <div className="product-body product-action-inner">
        {/* <button
          className={`btn-product btn-wishlist ${
            isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'
          }`}
          onClick={wishlistHandler}
          title={isWishlist ? 'Go to wishlist' : 'Add to wishlist'}
        >
          <span>
            {isWishlist ? 'go to wishlist' : 'add to wishlist'}
          </span>
        </button> */}

        {/* <div className="product-cat">
          <span className="mr-0">
            <Link to="#">{product.category[0]}</Link>
          </span>
        </div> */}

        <h3 className="product-title">
          <ProductLink product={product}>{product.name}</ProductLink>
        </h3>

        {isOutOfStock ? (
          <div className="product-price">
            <span className="out-price">
              $
              {price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : 0 < product.discount ? (
          <div className="product-price">
            <span className="new-price tw-font-[800]">
              $
              {product.salePrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span className="old-price">
              $
              {price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : (
          <div className="product-price tw-font-[800]">
            $
            {price.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        )}

        {product.excerpt && (
          <p className={styles.hyphenate}>{product.excerpt}</p>
        )}

        {/* <div className="ratings-container">
          <div className="ratings">
            <div
              className="ratings-val"
              style={{ width: product.ratings * 20 + '%' }}
            ></div>
          </div>
          <span className="ratings-text">
            ({product.reviews} Reviews )
          </span>
        </div> */}

        {/* {product.variants ? (
          product.variants[0].model ? (
            <div className="product-nav product-nav-thumbs">
              {product.variants.map((vari, i) => (
                <Link
                  to="#"
                  key={i}
                  className={0 === i ? 'active' : ''}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/' + vari.model}
                    alt="product desc"
                  />
                </Link>
              ))}
            </div>
          ) : (
            <div className="product-nav product-nav-dots">
              {product.variants.map((vari, i) => (
                <Link
                  to="#"
                  key={i}
                  className={0 === i ? 'active' : ''}
                  style={{ background: vari.color }}
                >
                  {' '}
                </Link>
              ))}
            </div>
          )
        ) : (
          ''
        )} */}
      </div>
    </div>
  ) : (
    ''
  );
};

ProductCard.propTypes = {
  product: typedef.Product.isRequired,
  showQuickView: PropTypes.func,
  isWishlist: PropTypes.bool,
  onToggleWishlist: PropTypes.func,
};

ProductCard.defaultProps = {
  showQuickView: () => {},
  isWishlist: false,
  onToggleWishlist: () => {},
};

export default ProductCard;
